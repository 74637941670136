<template>
  <div class="layout">
    <header>
      <div class="header-content">
        <div class="logo-title">
          <router-link to="/" class="home-link">
            <img src="../assets/logo.jpg" alt="Logo" class="logo">
            <h1>東宸資訊工程有限公司</h1>
          </router-link>
        </div>
        <nav>
          <ul>
            <li v-for="item in navItems" :key="item.id">
              <router-link :to="item.route">{{ item.text }}</router-link>
            </li>
          </ul>
        </nav>
      </div>
    </header>

    <div class="content-wrapper">
      <main class="container">
        <slot></slot>
      </main>

      <footer>
        <div class="footer-content">
          <p>&copy; {{ currentYear }} 東宸資訊工程有限公司. 版權所有.</p>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      navItems: [
        { id: 1, route: '/', text: '首頁' },
        { id: 2, route: '/services', text: '服務' },
        { id: 3, route: '/about', text: '關於我們' },
        { id: 4, route: '/contact', text: '聯繫我們' }
      ]
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
.home-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
}

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  width: 100%;
  background-color: #3B494D;
  color: #fff;
  padding: 1rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.logo-title {
  display: flex;
  align-items: center;
}

.logo {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

h1 {
  margin: 0;
  font-size: 1.5rem;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

nav ul li {
  margin-left: 20px;
}

nav ul li a {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: #e2e8f0;
}

nav ul li a.router-link-active {
  color: #e2e8f0;
  font-weight: bold;
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

main.container {
  flex: 1;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  box-sizing: border-box;
}

footer {
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 1rem 0;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.container, .content-wrapper {
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
</style>