import { createRouter, createWebHistory } from 'vue-router'
import pageHome from '../views/pageHome.vue'
import pageServices from '../views/pageServices.vue'
import pageAbout from '../views/pageAbout.vue'
import pageContact from '../views/pageContact.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: pageHome,
    meta: {
      title: '東宸資訊工程有限公司'
    }
  },
  {
    path: '/services',
    name: 'Services',
    component: pageServices,
    meta: {
      title: '服務項目'
    }
  },
  {
    path: '/about',
    name: 'About',
    component: pageAbout,
    meta: {
      title: '關於我們'
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: pageContact,
    meta: {
      title: '聯繫我們'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router