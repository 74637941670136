<template>
  <pageLayout>
    <!-- 主要介紹區塊 -->
    <section class="hero-section">
      <div class="content-box">
        <h2>公司簡介</h2>
        <p class="lead-text">東宸資訊工程有限公司是您值得信賴的IT解決方案提供商。我們致力於為企業提供全方位的資訊科技服務，幫助您在數位時代保持競爭優勢。</p>
      </div>
    </section>

    <!-- 核心服務區塊 -->
    <section class="services-grid">
      <h2>我們的核心服務</h2>
      <div class="grid-container">
        <div v-for="(service, index) in coreServices"
             :key="index"
             class="service-card"
             :class="{ 'animate-in': true }"
             :style="{ animationDelay: `${index * 0.1}s` }">
          <i :class="service.icon"></i>
          <h3>{{ service.title }}</h3>
          <p>{{ service.description }}</p>
        </div>
      </div>
    </section>

    <!-- 選擇我們的理由 -->
    <section class="why-us">
      <h2>為什麼選擇我們？</h2>
      <div class="features-grid">
        <div v-for="(feature, index) in features"
             :key="index"
             class="feature-item">
          <div class="feature-icon">{{ feature.icon }}</div>
          <h3>{{ feature.title }}</h3>
          <p>{{ feature.description }}</p>
        </div>
      </div>
    </section>

    <!-- 聯繫我們區塊 -->
    <section class="contact-section">
      <div class="content-box">
        <h2>聯繫我們</h2>
        <p>如果您有任何疑問或需求，請隨時與我們聯繫。我們期待為您提供專業的IT解決方案。</p>
        <router-link to="/contact" class="cta-button">立即聯繫</router-link>
      </div>
    </section>
  </pageLayout>
</template>

<script>
import pageLayout from '@/components/pageLayout.vue'

export default {
  name: 'PageHome',
  components: {
    pageLayout
  },
  data() {
    return {
      coreServices: [
        {
          icon: '🔒',
          title: '安全監控系統',
          description: '提供全方位的安全監控解決方案，保護您的資產安全。'
        },
        {
          icon: '🌐',
          title: '網路整合規劃',
          description: '專業的網路架構規劃，確保您的網路運作順暢。'
        },
        {
          icon: '💻',
          title: '軟體開發',
          description: '客製化軟體開發服務，滿足您的特定需求。'
        },
        {
          icon: '📊',
          title: '數據分析',
          description: '深入的數據分析服務，協助您做出明智的商業決策。'
        },
        {
          icon: '🔧',
          title: 'IT基礎設施維護',
          description: '專業的IT設施維護服務，確保系統穩定運行。'
        }
      ],
      features: [
        {
          icon: '⭐',
          title: '豐富的行業經驗',
          description: '多年的產業經驗，為您提供最專業的服務。'
        },
        {
          icon: '👥',
          title: '專業的技術團隊',
          description: '擁有經驗豐富的技術專家團隊。'
        },
        {
          icon: '🎯',
          title: '客製化解決方案',
          description: '根據您的需求，提供量身定制的解決方案。'
        },
        {
          icon: '🛠',
          title: '優質的售後服務',
          description: '提供持續的技術支援和售後服務。'
        }
      ]
    }
  }
}
</script>

<style scoped>
.hero-section {
  background-color: #f8fafc;
  padding: 3rem 2rem;
  border-radius: 8px;
  margin-bottom: 3rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.lead-text {
  font-size: 1.2rem;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
  color: #718096;
}

.services-grid {
  margin-bottom: 3rem;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.service-card {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.service-card i {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.service-card h3 {
  color: #2d3748;
  margin-bottom: 1rem;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.feature-item {
  text-align: center;
  padding: 1.5rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.contact-section {
  background: linear-gradient(135deg, #2c5282 0%, #2b6cb0 100%);
  color: white;
  padding: 3rem 2rem;
  border-radius: 8px;
  text-align: center;
  margin-top: 3rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cta-button {
  display: inline-block;
  background-color: #4ade80;
  color: white;
  padding: 0.8rem 2.5rem;
  border-radius: 4px;
  text-decoration: none;
  margin-top: 1.5rem;
  transition: all 0.3s ease;
  font-weight: 600;
  border: 2px solid transparent;
}
.cta-button:hover {
  background-color: #22c55e;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.contact-section h2 {
  color: white;
  margin-bottom: 1.5rem;
  font-size: 2rem;
}

.contact-section p {
  color: rgba(255, 255, 255, 0.9);
  max-width: 600px;
  margin: 0 auto 1.5rem;
  line-height: 1.6;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-in {
  animation: fadeIn 0.5s ease forwards;
}

h2 {
  color: #2d3748;
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 2rem;
}

@media (max-width: 768px) {
  .contact-section {
    padding: 2.5rem 1.5rem;
  }

  .cta-button {
    width: 100%;
    max-width: 300px;
  }
}
</style>