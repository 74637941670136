<template>
  <div id="app">
    <app-layout>
      <router-view></router-view>
    </app-layout>
  </div>
</template>

<script>
import appLayout from './components/appLayout.vue'

export default {
  name: 'App',
  components: {
    appLayout
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      document.title = to.meta.title || '東宸資訊工程有限公司';

      let metaDescription = document.querySelector('meta[name="description"]');
      if (!metaDescription) {
        metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        document.head.appendChild(metaDescription);
      }
      metaDescription.content = '提供專業的IT解決方案，安全監控系統、網路整合規劃、軟體開發、數據分析等服務。';

      next();
    })
  }
}
</script>


<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow-y: scroll;
}

:root {
  overflow-y: auto;
  overflow-x: hidden;
}

:root body {
  position: absolute;
}

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url('@/assets/background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
}

#app::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 0;
}

.layout {
  position: relative;
  z-index: 1;
}
</style>