<template>
  <pageLayout>
    <!-- 公司簡介區塊 -->
    <section class="intro-section">
      <div class="content-card">
        <h2>公司簡介</h2>
        <p>東宸資訊工程有限公司是一家專業的資訊科技服務提供商，致力於為企業和組織提供全方位的IT解決方案。我們的使命是透過創新的技術和卓越的服務，幫助客戶實現數位轉型，提高運營效率，並在競爭激烈的市場中保持領先地位。</p>
      </div>
    </section>

    <!-- 專業領域區塊 -->
    <section class="expertise-section">
      <h2>專業領域</h2>
      <div class="expertise-grid">
        <div v-for="(item, index) in expertise"
             :key="index"
             class="expertise-card"
             :class="{ 'animate-in': true }"
             :style="{ animationDelay: `${index * 0.1}s` }">
          <span class="expertise-icon">{{ item.icon }}</span>
          <span class="expertise-text">{{ item.text }}</span>
        </div>
      </div>
    </section>

    <!-- 方法區塊 -->
    <section class="approach-section">
      <h2>我們的方法</h2>
      <div class="approach-grid">
        <div v-for="(approach, index) in approaches"
             :key="index"
             class="approach-card"
             :class="{ 'animate-in': true }"
             :style="{ animationDelay: `${index * 0.1}s` }">
          <div class="approach-content">
            <span class="approach-icon">{{ approach.icon }}</span>
            <h3>{{ approach.title }}</h3>
            <p>{{ approach.description }}</p>
          </div>
        </div>
      </div>
    </section>

    <!-- 結語區塊 -->
    <section class="conclusion-section">
      <div class="content-card">
        <p>選擇東宸資訊工程，就是選擇一個可靠的IT合作夥伴。我們期待與您攜手，共同應對數位化挑戰，實現業務的持續增長和成功。</p>
      </div>
    </section>
  </pageLayout>
</template>

<script>
import pageLayout from '@/components/pageLayout.vue'

export default {
  name: 'PageAbout',
  components: {
    pageLayout
  },
  data() {
    return {
      expertise: [
        { icon: '🔒', text: '安全監控系統' },
        { icon: '🌐', text: '網路整合規劃' },
        { icon: '🔧', text: 'IT支持與維護' },
        { icon: '📊', text: '數據分析' },
        { icon: '💻', text: '軟體開發' },
        { icon: '🎨', text: '網頁設計' },
        { icon: '🚀', text: '前沿技術' }
      ],
      approaches: [
        {
          icon: '👥',
          title: '客戶至上',
          description: '以客戶需求為核心，提供量身定制的解決方案。'
        },
        {
          icon: '💡',
          title: '技術創新',
          description: '持續追蹤最新技術趨勢，確保解決方案的先進性。'
        },
        {
          icon: '✨',
          title: '品質保證',
          description: '嚴格的品質管理流程，確保服務的穩定性和可靠性。'
        },
        {
          icon: '🤝',
          title: '持續支持',
          description: '提供全面的售後支持，確保系統持續運作順暢。'
        }
      ]
    }
  }
}
</script>

<style scoped>
section {
  margin-bottom: 3rem;
}

.content-card {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
  color: #2d3748;
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 1.8rem;
}

.expertise-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
}

.expertise-card {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: all 0.3s ease;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

.expertise-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.expertise-icon {
  font-size: 1.5rem;
}

.expertise-text {
  font-size: 1.1rem;
  color: #4a5568;
}

.approach-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.approach-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

.approach-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

.approach-content {
  padding: 2rem;
  text-align: center;
}

.approach-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  display: block;
}

.approach-content h3 {
  color: #2d3748;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.approach-content p {
  color: #4a5568;
  line-height: 1.6;
}

.conclusion-section .content-card {
  background: linear-gradient(135deg, #4a5568 0%, #2d3748 100%);
  color: white;
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.8;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-in {
  animation: fadeIn 0.5s ease forwards;
}

@media (max-width: 768px) {
  section {
    margin-bottom: 2rem;
  }

  .content-card {
    padding: 1.5rem;
  }

  .expertise-grid,
  .approach-grid {
    grid-template-columns: 1fr;
  }

  .expertise-card {
    margin-bottom: 1rem;
  }
}
</style>