<template>
  <pageLayout>
    <div class="services-container">
      <div v-for="(service, index) in services"
           :key="index"
           class="service-card"
           :class="{ 'animate-in': true }"
           :style="{ animationDelay: `${index * 0.1}s` }">
        <div class="service-header">
          <h2>{{ service.title }}</h2>
        </div>
        <div class="service-content">
          <p>{{ service.description }}</p>
        </div>
      </div>
    </div>
  </pageLayout>
</template>

<script>
import pageLayout from '@/components/pageLayout.vue'

export default {
  name: 'PageServices',
  components: {
    pageLayout
  },
  data() {
    return {
      services: [
        {
          title: '門禁系統｜監視器',
          description: '提供先進的門禁管控和監視系統服務，確保您的辦公環境安全無虞。我們的解決方案可以整合多種設備，實現智能化管理和即時監控。'
        },
        {
          title: '弱電工程',
          description: '專業的弱電系統規劃與安裝，包括通訊、網路、安防等系統，我們的團隊擁有豐富的經驗，能為各類場景和各式空間提供完善適合的弱電解決方案。'
        },
        {
          title: '網路整合規劃',
          description: '為您的企業提供全面的網路整合規劃，從需求分析到系統實施，確保您的網路基礎設施能夠滿足現在及未來的業務需求，其中的解決方案還會將各種新趨勢、雲端整合納入。'
        },
        {
          title: '資訊硬體維護',
          description: '提供全方位的IT硬體維護服務，包括定期檢查、故障排除和系統升級。我們的技術團隊能夠快速處理並解決各種硬體問題，確保您的業務運營不受中斷。'
        },
        {
          title: '數據分析',
          description: '運用先進的數據分析工具和技術，幫助您從海量數據中獲取有價值的見解。我們的分析服務可以幫助您優化業務流程、預測市場趨勢、提高決策效率。'
        },
        {
          title: '軟體開發',
          description: '提供客製化的軟體開發服務，從桌面應用到企業級系統，我們能為您量身打造。我們採用敏捷開發方法，確保開發過程自訂著您和及時。'
        },
        {
          title: '網頁設計',
          description: '創造美觀、響應式和用戶友好的網站設計，我們注重用戶體驗、確保您的網站在各種設備上都能完美呈現，同時優化搜索引擎表現，提高您的線上曝光度。'
        },
        {
          title: '演算法開發',
          description: '專門為複雜問題開發高效的演算法解決方案，我們的團隊擅長機器學習、人工智能和大數據處理等領域的演算法設計，幫助您實現數據驅動的創新。'
        }
      ]
    }
  }
}
</script>

<style scoped>
.services-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem 0;
}

.service-card {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

.service-header {
  background: linear-gradient(135deg, #475569 0%, #64748b 100%);
  padding: 1.5rem;
  color: white;
}

.service-header h2 {
  color: white;
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
}

.service-content {
  padding: 1.5rem;
  background: white;
}

.service-content p {
  color: #4a5568;
  line-height: 1.6;
  margin: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-in {
  animation: fadeIn 0.5s ease forwards;
}

@media (max-width: 768px) {
  .services-container {
    grid-template-columns: 1fr;
    padding: 1rem;
  }

  .service-card {
    margin-bottom: 1rem;
  }
}
</style>