<template>
  <pageLayout>
    <div class="contact-container">
      <!-- 公司聯繫資訊卡片 -->
      <div class="contact-card info-card">
        <h2>公司聯繫資訊</h2>
        <div class="info-grid">
          <div class="info-item">
            <div class="icon">🏢</div>
            <div class="text-content">
              <h3>公司名稱</h3>
              <p>東宸資訊工程有限公司</p>
            </div>
          </div>

          <div class="info-item">
            <div class="icon">📝</div>
            <div class="text-content">
              <h3>統一編號</h3>
              <p>82996460</p>
            </div>
          </div>

          <div class="info-item">
            <div class="icon">📱</div>
            <div class="text-content">
              <h3>手機</h3>
              <a href="tel:0911187040" class="contact-link">0911187040</a>
            </div>
          </div>

          <div class="info-item">
            <div class="icon">✉️</div>
            <div class="text-content">
              <h3>電子郵件</h3>
              <a href="mailto:support@jmc-hitech.com" class="contact-link">support@jmc-hitech.com</a>
            </div>
          </div>

          <div class="info-item">
            <div class="icon">📍</div>
            <div class="text-content">
              <h3>地址</h3>
              <p>台南市關廟區東勢里和平路22巷3號</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 營業時間卡片 -->
      <div class="contact-card hours-card">
        <h2>營業時間</h2>
        <div class="hours-grid">
          <div class="hours-item">
            <div class="day">週一至週五</div>
            <div class="time">上午 9:00 - 下午 6:00</div>
          </div>
          <div class="hours-item">
            <div class="day">週六、週日</div>
            <div class="time">休息</div>
          </div>
          <div class="hours-item">
            <div class="day">國定假日</div>
            <div class="time">休息</div>
          </div>
        </div>
      </div>
    </div>
  </pageLayout>
</template>

<script>
import pageLayout from '@/components/pageLayout.vue'

export default {
  name: 'PageContact',
  components: {
    pageLayout
  }
}
</script>

<style scoped>
.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.contact-card {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

.contact-card h2 {
  color: #2d3748;
  font-size: 1.8rem;
  margin-bottom: 2rem;
  text-align: center;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.info-item {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem;
  background: #f8fafc;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.info-item:hover {
  transform: translateY(-2px);
}

.icon {
  font-size: 1.5rem;
  color: #4a5568;
  min-width: 2rem;
  text-align: center;
}

.text-content {
  flex: 1;
}

.text-content h3 {
  color: #4a5568;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.text-content p {
  color: #718096;
  line-height: 1.5;
}

.contact-link {
  color: #3182ce;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-link:hover {
  color: #2c5282;
  text-decoration: underline;
}

.hours-grid {
  display: grid;
  gap: 1rem;
}

.hours-item {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background: #f8fafc;
  border-radius: 8px;
}

.hours-item .day {
  color: #4a5568;
  font-weight: 600;
}

.hours-item .time {
  color: #718096;
}

@media (max-width: 768px) {
  .info-grid {
    grid-template-columns: 1fr;
  }

  .contact-card {
    padding: 1.5rem;
  }

  .hours-item {
    flex-direction: column;
    text-align: center;
    gap: 0.5rem;
  }
}

/* 動畫效果 */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.contact-card {
  animation: fadeIn 0.5s ease forwards;
}
</style>